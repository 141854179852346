import React, { Component, Suspense, useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  redirect,
} from 'react-router-dom'
import './scss/style.scss'
import PropTypes from 'prop-types'
import { UserProvider, UserContext } from './UserProvider'
import { fetchUserData } from './utils/api'
import { ErrorBoundary } from './ErrorBoundary'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const PrivateRoute = ({ element }) => {
  // State to track authentication status
  const [userData, setUserData] = useContext(UserContext)
  const [authenticated, setAuthenticated] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchUserData()
        setUserData(fetchedData)
        setAuthenticated(true)
      } catch (err) {
        navigate('/login')
      }
    }

    fetchData()
  }, [authenticated, navigate])

  // Rendering the specified element if authenticated, otherwise redirecting to login
  return authenticated ? element : <></>
}

// PropTypes for type-checking 'element' prop
PrivateRoute.propTypes = {
  element: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]).isRequired,
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Kiosk = React.lazy(() => import('./views/kiosk/Kiosk'))

class App extends Component {
  render() {
    return (
      <UserProvider>
        <Router basename="/">
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="*"
                element={
                  <PrivateRoute
                    element={
                      <ErrorBoundary>
                        <DefaultLayout />
                      </ErrorBoundary>
                    }
                  />
                }
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route exact path="/kiosk" name="Kiosk" element={<Kiosk />} />
            </Routes>
          </Suspense>
        </Router>
      </UserProvider>
    )
  }
}

export default App
