import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const UserContext = createContext()
const UserProvider = ({ children }) => {
  // Initially the userData is undefined, until we fetch the userData
  const [userData, setUserData] = useState({})
  return <UserContext.Provider value={[userData, setUserData]}>{children}</UserContext.Provider>
}
UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]).isRequired,
}

export { UserProvider, UserContext }
